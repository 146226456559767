import React from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import Now from "../../content/now.mdx"
import SEO from "../components/SEO"

const NowPage = () => {
  return (
    <Layout>
      <SEO title="Now" />
      <Wrapper>
        <div className="content">
          <h1>/now</h1>
          <Now />
        </div>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  .content {
    padding: 0 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    ul,
    ol {
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
    }

    li {
      margin-bottom: 0.4em;
    }

    div {
      margin: 0.5rem 0;
    }
  }

  @media screen and (max-width: 500px) {
    .content {
      padding: 0 1em;
    }
  }
`

export default NowPage

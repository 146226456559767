import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Location`}</strong>{`: Vancouver, BC.`}</p>
    <p><strong parentName="p">{`My personal mantra`}</strong>{`: Be Here. Now. Nowhere Else.`}</p>
    <p>{`I've been in software from the start and been with Startups for over
a decade now. I have always been obsessed with perfecting my craft
of programming. I love breaking down problems into their core elements
and finding the right architecture to solve the problem.`}</p>
    <p>{`I grow my digital garden here. If you see me taking the analogy of
maintaining a garden too far at times, please ignore my transgression.`}</p>
    <p>{`Here's what I'm up to right now:`}</p>
    <ul>
      <li parentName="ul">{`I run a `}<a parentName="li" {...{
          "href": "https://team8solutions.com"
        }}>{`Services Startup`}</a>{` specializing on building large-scale SaaS Products and focusing on delivering value to end users.`}</li>
      <li parentName="ul">{`Focusing on blogging and maintaining my digital garden as an increasingly important and deliberate practice of getting to the next level in Software Architecture.`}</li>
    </ul>
    <p>{`I've done some conference speaking in the past, and I'm open to new speaking opportunities so get in touch if you have an event you'd like me to speak at.`}</p>
    <p>{`I'm always looking to grab coffee with interesting people so get in touch: `}<a parentName="p" {...{
        "href": "mailto:subhash.bhushan@gmail.com"
      }}>{`subhash.bhushan@gmail.com`}</a></p>
    <hr></hr>
    <p><em parentName="p">{`Last updated June 2020`}</em></p>
    <p>{`Inspired by Derek Sivers and `}<a parentName="p" {...{
        "href": "https://sivers.org/nowff"
      }}>{`The /Now Movement`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      